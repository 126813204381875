(function () {
	'use strict';
	angular
		.module('app')
		.controller('GroupSelectorCtrl', [
			'$scope',
			'dataStore',
			'utilities',
			'seedcodeCalendar',
			'firebaseIO',
			'groupSelector',
			GroupSelectorCtrl,
		]);

	function GroupSelectorCtrl(
		$scope,
		dataStore,
		utilities,
		seedcodeCalendar,
		firebaseIO,
		groupSelector
	) {
		let originalGroupList;

		// Initialize groupList
		$scope.loading = true;
		$scope.selectedGroup = $scope.data.selectedGroup;

		groupSelector.buildGroupList((groupList) => {
			originalGroupList = JSON.parse(JSON.stringify(groupList));
			$scope.groupList = groupList;
			$scope.$evalAsync(() => {
				$scope.loading = false;
			});
		});

		$scope.help = utilities.help;

		$scope.changeGroup = function (update) {
			const user = seedcodeCalendar.get('user');
			const callback = $scope.popover.config.callback;
			$scope.popover.config.show = false;
			if (update && $scope.selectedGroup !== user.group.id) {
				dataStore.saveState('groupId', $scope.selectedGroup);
				setTimeout(function () {
					location.reload();
				}, 200);
			} else {
				if (callback) {
					callback();
				}
			}
		};

		$scope.selectGroup = function (group) {
			$scope.selectedGroup = group.id;
		};

		$scope.editGroups = function () {
			$scope.editMode = true;
		};

		$scope.doneEditGroups = function () {
			$scope.editMode = false;
		};

		$scope.editGroupDescription = editGroupDescription;

		function editGroupDescription(group) {
			const user = seedcodeCalendar.get('user');
			const isPrimary = group.id === user.primaryGroup.id;
			const isCurrent = group.id === user.group.id;

			if (isPrimary) {
				// Update primary group data
				firebaseIO.setUserData(
					user.id,
					'user/group',
					'description',
					group.description,
					false,
					processUpdate,
					null
				);
			} else {
				// Update additional group data
				firebaseIO.setUserData(
					user.id,
					`user/additionalGroups/${group.id}`,
					'description',
					group.description,
					false,
					processUpdate,
					null
				);
			}

			function processUpdate() {
				if (isCurrent) {
					user.group.description = group.description;
				}
				if (isPrimary) {
					user.primaryGroup.description = group.description;
				} else {
					for (let property in user.additionalGroups) {
						if (user.additionalGroups[property].id === group.id) {
							user.additionalGroups[property].description =
								group.description;
							break;
						}
					}
				}
			}
		}
	}
})();
